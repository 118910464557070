.Home{
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.cardBox {
    border-radius: 8px;
    box-shadow: 0 1px 12px rgba(0,0,0,0.25);
    border: 1px solid rgba(255,255,255,0.3);
    transition: background-color 2s;
}

.cardBox:hover {
    transition: background-color 2s;
}

.formHome {
    margin: 2rem;
    display: flex;
    flex-direction: column;
}








